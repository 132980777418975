<template>
  <div v-if="items" class="discount-catalog__items">
    <product-card v-for="item in items" :key="item.id" :item="item" />
  </div>
</template>

<script>
export default {
  name: "ProductsList",
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
  },
  props: {
    items: {
      type: Array,
      requred: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.discount-catalog__items{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 758px) {
   .discount-catalog__items{
     display: flex;
     flex-flow: column;
   }
}
</style>
